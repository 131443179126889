<template>
  <div class="v-missing">
    <t color="gray-2" secondary xxl b>Missing</t>
  </div>
</template>

<script>
export default {
  name: 'VMissing',
};
</script>

<style lang="sass">
@import odd-ds/dist/lib/index.sass

.v-missing
  height: 100%

  +d-flex-r(center, center)
</style>
